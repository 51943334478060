import { useMutation, useQuery } from "@blitzjs/rpc"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Avatar,
  Box,
  Button,
  Card,
  HStack,
  Heading,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Progress,
  Tag,
  TagLabel,
  TagProps,
  Tooltip,
  styled,
  useColorModeValue,
} from "@chakra-ui/react"
import { defaultProfileImage, shouldSync } from "app/commonUtils"
import { ActivityItem } from "app/core/components/ActivityItem"
import { ErrorAlert } from "app/core/components/ErrorAlert"
import { FeedSuspense } from "app/core/layouts/Layout"
import getFeed from "app/queries/getFeed"
import refreshActivities from "app/users/mutations/refreshActivities"
import getActivities from "app/users/queries/getActivities"
import { SerializedActivity } from "app/users/queries/getActivity"
import getClubActivities from "app/users/queries/getClubActivities"
import { SummaryClub } from "app/users/queries/getCurrentUser"
import Link from "next/link"
import { UserContext } from "pages/_app"
import { ReactNode, useContext, useEffect, useMemo, useState } from "react"
import {
  IoArrowForwardCircleSharp,
  IoEllipsisHorizontal,
  IoPeopleCircle,
  IoStatsChartSharp,
} from "react-icons/io5"
import { SiStrava } from "react-icons/si"
import { accountsFromUser } from "./AuthButtons"
import InviteModal from "./InviteModal"

export type FeedType = "spava" | "you" | string

const StyledTagSwitch = styled(Tag, {
  baseStyle: {
    fontSize: 18,
    cursor: "pointer",
    height: 10,
  },
})

const TagSwitch = ({
  children,
  selected,
  ...rest
}: { selected: boolean; children: ReactNode } & TagProps) => {
  return (
    <StyledTagSwitch
      size="lg"
      borderRadius="full"
      mr={2}
      paddingLeft={selected ? 2.5 : undefined}
      border={selected ? "solid 2px" : undefined}
      borderColor="gray.500"
      variant={selected ? "solid" : "subtle"}
      {...rest}
    >
      {children}
    </StyledTagSwitch>
  )
}

const EMPTY_CLUBS = []

export const MainHome = ({ clubSlug: pageClubSlug }: { clubSlug?: string }) => {
  const { user, refetch: refetchUser } = useContext(UserContext)
  const [selectedFeed, setSelectedFeed] = useState<FeedType>(
    pageClubSlug ? `club.${pageClubSlug}` : "spava"
  )

  const { strava } = accountsFromUser(user)

  const clubs = user?.clubs ?? EMPTY_CLUBS

  const selectedClub = useMemo(() => {
    if (selectedFeed.startsWith("club")) {
      return clubs.find(
        (club) => club.url === selectedFeed.slice(5) || club.id.toString() === selectedFeed.slice(5)
      )
    }
    return undefined
  }, [clubs, selectedFeed])

  const clubSlug = pageClubSlug ? pageClubSlug : selectedClub?.url || selectedClub?.id.toString()

  const query =
    selectedFeed === "spava" ? getFeed : selectedFeed === "you" ? getActivities : getClubActivities
  const [response, { isLoading, isError, refetch: refetchFeed, remove }] = useQuery(
    query,
    // @ts-ignore
    clubSlug ? { clubSlug: clubSlug || selectedClub.url } : null,
    {
      staleTime: selectedFeed === "you" ? 5000 : 15000,
      useErrorBoundary: false,
      enabled: false,
      suspense: false,
    }
  )
  const activities = response?.activities ?? []

  const [sync, { isLoading: syncLoading }] = useMutation(refreshActivities)
  const lastSync = user?.lastSync?.getTime() ?? null

  const isInSpavaClub = clubs.some((club) => club.id === 1096706)
  const [activeClubs, inactiveClubs] = useMemo(
    () =>
      clubs.reduce<[SummaryClub[], SummaryClub[]]>(
        (acc, club) => {
          if (club.spavaCount > 1) {
            acc[0].push(club)
          } else {
            acc[1].push(club)
          }
          return acc
        },
        [[], []]
      ),
    [clubs]
  )

  const thenBackground = useColorModeValue(
    "linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))",
    "linear-gradient(to left, rgba(26,32,44,1), rgba(255,255,255,0))"
  )

  useEffect(() => {
    void (async () => {
      if (clubSlug) {
        return
      }
      const doSync = shouldSync(lastSync, selectedFeed === "you" ? 30 : 300)
      if (doSync) {
        try {
          await sync()
          await Promise.all([refetchFeed(), refetchUser()])
        } catch (error) {
          console.error(error)
        }
      }
    })()
  }, [clubSlug, lastSync, selectedFeed, sync, refetchFeed, refetchUser])

  useEffect(() => {
    remove()
    refetchFeed()
      .then(() => {})
      .catch((error) => console.error(error))
  }, [refetchFeed, remove, selectedFeed])

  return (
    <div>
      {/* <CommunityAlert /> */}
      {syncLoading && (
        <Box top={0} left={0} position="fixed" w="full">
          <Progress mt="0" w="full" size="xs" isIndeterminate />
        </Box>
      )}
      <HStack mb={5}>
        <Heading as="h3" fontSize="3xl" mb={0} fontWeight="medium" letterSpacing="tight" flex="1">
          {selectedFeed === "spava"
            ? "Community Feed"
            : selectedFeed === "you"
            ? "Your Feed"
            : response?.feedType === "club"
            ? // @ts-ignore
              response.name
            : selectedClub
            ? selectedClub.name
            : " "}
        </Heading>

        {selectedFeed === "spava" ? (
          <Link href="/stats" passHref>
            <Button as="a" variant="outline" size="sm" leftIcon={<IoStatsChartSharp />}>
              Stats
            </Button>
          </Link>
        ) : selectedFeed === "you" ? (
          <Link href="/you/stats" passHref>
            <Button as="a" variant="outline" size="sm" leftIcon={<IoStatsChartSharp />}>
              See your stats
            </Button>
          </Link>
        ) : null}

        {selectedClub && (
          <Popover offset={[0, 0]} placement="bottom-end">
            <PopoverTrigger>
              <Button variant="ghost">
                <IoEllipsisHorizontal />
              </Button>
            </PopoverTrigger>
            <PopoverContent outline="none" opacity={1}>
              <PopoverArrow opacity={1} />
              {/* <PopoverCloseButton /> */}
              {/* <PopoverHeader>Confirmation!</PopoverHeader> */}
              <PopoverBody p={5}>
                <InviteModal url={`https://spava.club/clubs/${clubSlug}`}>
                  <Button rightIcon={<IoArrowForwardCircleSharp />} w="full" mb={4}>
                    Invite Members
                  </Button>
                </InviteModal>

                <Button
                  as="a"
                  target="_blank"
                  href={`https://strava.com/clubs/${clubSlug}`}
                  w="full"
                  rightIcon={<SiStrava />}
                >
                  Open in Strava
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </HStack>
      {!!user && (
        <Box mb={1} pos="relative">
          <Box overflowX="scroll" w="auto" whiteSpace="nowrap" pr={20} pb={3}>
            <TagSwitch selected={selectedFeed === "spava"} onClick={() => setSelectedFeed("spava")}>
              <Icon as={IoPeopleCircle} fontSize={36} ml={-2.5} mr={1} />
              <TagLabel display="block" px={1}>
                Community
              </TagLabel>
            </TagSwitch>
            <TagSwitch
              onClick={() => setSelectedFeed("you")}
              selected={selectedFeed === "you"}
              mr={0}
            >
              <Avatar
                src={user?.picture || defaultProfileImage}
                size="sm"
                // name={club.name}
                ml={-2}
                mr={2}
              />
              <TagLabel display="block" px={1}>
                Me
              </TagLabel>
              {!!user?.unseenActivities && (
                <Tag
                  ml={2}
                  variant="solid"
                  size="md"
                  colorScheme="red"
                  fontSize={14}
                  borderRadius="full"
                >
                  {user?.unseenActivities}
                </Tag>
              )}
            </TagSwitch>

            <Box
              display="inline-block"
              borderRight="1px solid"
              borderRightColor="gray.500"
              height={6}
              my={2}
              mx={4}
              opacity={0.3}
            ></Box>

            {activeClubs.map((club) => (
              <TagSwitch
                key={club.id}
                mr={2}
                selected={selectedFeed === "club." + club.url || selectedFeed === "club." + club.id}
                onClick={() => setSelectedFeed(club.url ? "club." + club.url : "club." + club.id)}
              >
                <Avatar src={club.profile_medium} size="sm" name={club.name} ml={-2} mr={2} />
                <TagLabel
                  display="block"
                  maxW={64}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  fontWeight="normal"
                >
                  {club.name}
                </TagLabel>
              </TagSwitch>
            ))}

            {activeClubs.length > 0 && (inactiveClubs.length > 0 || !isInSpavaClub) && (
              <Box
                display="inline-block"
                borderRight="1px solid"
                borderRightColor="gray.500"
                height={6}
                my={2}
                ml={2}
                mr={4}
                opacity={0.3}
              ></Box>
            )}

            {inactiveClubs.length > 0 && (
              <Tooltip
                label="Invite another friend from your favourite Strava club to Spava and it will appear here."
                aria-label="A tooltip"
              >
                <Box display="inline">
                  <TagSwitch selected={false} justifyContent="center">
                    <TagLabel display="block" fontWeight="normal">
                      {inactiveClubs.length} more
                    </TagLabel>
                    <Icon as={IoEllipsisHorizontal} fontSize={20} ml={2} mt={1} />
                  </TagSwitch>
                </Box>
              </Tooltip>
            )}

            {!isInSpavaClub && (
              <TagSwitch variant="subtle" selected={false}>
                {/* <Icon as={IoLinkSharp} fontSize={38} mr={2} /> */}
                <Avatar
                  src="https://dgalywyr863hv.cloudfront.net/pictures/clubs/1096706/26104899/1/medium.jpg"
                  size="sm"
                  name="Spava Club on Strava"
                  ml={-2}
                  mr={2}
                />
                <TagLabel
                  fontWeight="normal"
                  fontSize={14}
                  as="a"
                  href="https://www.strava.com/clubs/spava"
                  target="_blank"
                >
                  Join the Spava Club on Strava.
                </TagLabel>
                <Icon as={IoArrowForwardCircleSharp} fontSize={18} ml={1} />
              </TagSwitch>
            )}
          </Box>
          <Box bg={thenBackground} w={20} h={12} pos="absolute" right={0} top={0} zIndex={7}></Box>
        </Box>
      )}
      {!!user && selectedClub && clubSlug && (
        <SelectedClub
          slug={clubSlug}
          count={selectedClub.spavaCount}
          totalCount={selectedClub.member_count}
        />
      )}

      <Feed hasStrava={!!strava} isError={isError} isLoading={isLoading} activities={activities} />
    </div>
  )
}

const SelectedClub = ({
  slug,
  count,
  totalCount,
}: {
  slug: string
  count: number
  totalCount: number
}) => {
  const extra = count / totalCount < 0.75
  // const extra = false

  return (
    <Box
      px={5}
      py={4}
      bg="rgba(0,0,0,0.2)"
      borderRadius={10}
      mb={6}
      // border="1px solid"
      // borderColor="gray.500"
    >
      <Box fontSize={extra ? 18 : 15} fontWeight="medium">
        {extra ? "Only" : ""} <strong>{count}</strong> out of <strong>{totalCount}</strong> club
        members are on Spava.
        {extra && (
          <Box mt={3}>
            <InviteModal url={`https://spava.club/clubs/${slug}`}>
              <Button rightIcon={<IoArrowForwardCircleSharp />} w="full">
                Send a Link
              </Button>
            </InviteModal>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const Feed = ({
  isLoading,
  activities,
  isError,
  hasStrava,
}: {
  hasStrava: boolean
  isError: boolean
  isLoading: boolean
  activities: SerializedActivity[]
}) => {
  return (
    <>
      {isLoading ? (
        <FeedSuspense />
      ) : isError || !activities ? (
        <ErrorAlert />
      ) : activities.length === 0 ? (
        <Alert status="info" mb={6}>
          <AlertIcon alignSelf="flex-start" />
          <Box flex="1">
            <AlertTitle>No Activities Yet!</AlertTitle>
            {hasStrava ? (
              <AlertDescription>
                To see your first activity here, start recording on Strava and play your favourites
                on Spotify. After you upload your workout, the music you played will appear here
                auto-magically.
              </AlertDescription>
            ) : (
              <AlertDescription>Connect Strava to sync your activities in here!</AlertDescription>
            )}
          </Box>
        </Alert>
      ) : (
        activities?.map((activity) => (
          <Card key={activity.id} mb={8} border="1px" borderColor="gray.500">
            <ActivityItem activity={activity} short={true} />
          </Card>
        ))
      )}
    </>
  )
}
