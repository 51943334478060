import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from "@chakra-ui/react"

export function ErrorAlert() {
  return (
    <Alert status="error" mb={6}>
      <AlertIcon alignSelf="flex-start" />
      <Box flex="1">
        <AlertTitle>Error!</AlertTitle>
        <AlertDescription>Could not load.</AlertDescription>
      </Box>
    </Alert>
  )
}
